<template>
  <div>
    <h4>{{ name }}</h4>
    <v-form>
      <v-container>
        <v-row class="mt-3">
          <v-col cols="12" md="8">
            <h5>Please fill in the basic rent for your venue</h5>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              min="0"
              :rules="[getRules.required, getRules.nonNegativeQuantity, getRules.germanNumber]"
              v-model="form.basic_rent"
              label="Enter Rent Amount"
            >
              <template slot="append">
                <v-icon size="20">mdi-currency-eur</v-icon>
              </template></v-text-field
            >
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="px-3 py-2 default" outlined>What is the rental amount of this particular venue?</v-card>
          </v-col>
        </v-row>

        <v-row class="mt-8">
          <v-col cols="12" md="4">
            <h5>Booking contact</h5>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <v-text-field v-model="form.full_name" label="Full Name"></v-text-field>
          </v-col>
          <v-col cols="12" md="4"> </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="form.company" label="Company"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="form.job_title" label="Job Title"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="px-3 py-2 default" outlined
              >Provide the contact details for the booking/reservations?</v-card
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.email"
              :rules="[getRules.required, getRules.email]"
              type="email"
              label="Email"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="form.phone" :rules="[getRules.required]" label="Phone"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-btn color="primary" @click="this.continue" class="float-right mt-5 mr-3">Continue</v-btn>
    <v-btn
      color="secondary"
      class="float-right mt-5 mr-1"
      @click="submit('draft')"
      v-if="(getVendor && getVendor.status !== 'active') || getIsVendorUpdate === false"
      >Save As Draft</v-btn
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BasicRent",
  props: ["name", "index", "form"],
  components: {},
  data: () => ({}),
  computed: {
    ...mapGetters(["getRules", "getVendor", "getIsVendorUpdate"])
  },
  methods: {
    async submit(type = "draft") {
      this.$emit("submit", type);
    },
    continue() {
      this.$emit("locationNextStep", this.index);
    }
  }
};
</script>
<style scoped>
.custom-item-column {
  padding: 7px !important;
  flex-grow: 0 !important;
}
.bg-color-grey {
  background-color: #f9f9f9;
}

.color-grey {
  color: #f9f9f9;
}
.equipment-height {
  height: 35px;
}

.pointer {
  cursor: pointer;
}
</style>
